<template>
  <div>
    <div id="chart-container" style="width: 100%; height: 400px"></div>
  </div>
</template>

<script>
export default {
  mounted () {
    // 获取图表容器
    const chartContainer = document.getElementById('chart-container')
    // 使用 echarts 渲染图表
    const myChart = this.$echarts.init(chartContainer)
    myChart.setOption({
      title: {
        text: '堆叠折线图'
      },
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        data: ['邮件营销', '联盟广告', '视频广告', '直接访问', '搜索引擎']
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      toolbox: {
        feature: {
          saveAsImage: {}
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          name: '邮件营销',
          type: 'line',
          stack: '总量',
          data: [120, 132, 101, 134, 90, 230, 210]
        },
        {
          name: '联盟广告',
          type: 'line',
          stack: '总量',
          data: [220, 182, 191, 234, 290, 330, 310]
        },
        {
          name: '视频广告',
          type: 'line',
          stack: '总量',
          data: [150, 232, 201, 154, 190, 330, 410]
        },
        {
          name: '直接访问',
          type: 'line',
          stack: '总量',
          data: [320, 332, 301, 334, 390, 330, 320]
        },
        {
          name: '搜索引擎',
          type: 'line',
          stack: '总量',
          data: [820, 932, 901, 934, 1290, 1330, 1320]
        }
      ]
    })
  }
}
</script>

<style></style>
var data = [ { name: 'A', value: 30 }, { name: 'B', value: 50 }, { name: 'C',
value: 20 }, { name: 'D', value: 70 }, { name: 'E', value: 40 } ] //
对数据进行降序排列 data.sort(function (a, b) { return b.value - a.value })

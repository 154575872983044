<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import TablePage from '@/components/tablePage'
import {
  purchasePriceAnalysis,
  purchasePriceAnalysisSummary
} from '@/api/purchase/report' // 门店库存查询
import moment from "moment";
export default {
  name: 'PriceAnalyse',
  components: { TablePage },
  data() {
    return {
      options: {
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        body: {},
        getListApi:purchasePriceAnalysis,
        getSummaryApi: purchasePriceAnalysisSummary,
        exportOption: {
           fastExportUrl:'/api/system/purchase/reportForms/exportPurchasePriceAnalysis',
           exportName: '采购价格分析'
        },
        title: '采购价格分析',
        search: [
           {
            label: "日期",
            type: "month",
            filter: "billDate",
            disabledFuture: true,
            model: moment().format("YYYY-MM"),
            defaultValue: moment().format("YYYY-MM"),
            filterFnc: (a) => ({ beginBillDate: a + "-01" + " 00:00:00" }),
          },
          {
            type: 'filters',
            tip: '全部/商品编码/商品名称',
            isRestore: true,
            model: '',
            filters: [
              { filter: 'query', label: '全部' },
              { filter: 'goodsNos', label: '商品编码' },
              { filter: 'goodsNames', label: '商品名称' },
            ]
          },
           this.$select({
            key: 'listGoods',
            option: {
              filter: 'goodsIds',
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: 'more',
                    option: {
                      title: '选择商品',
                      width: 1250,
                      type: 'TreeAndTable',
                      formData: this.$dialog({ key: 'goods' })
                    }
                  }
                ]
              }
            }
          }),
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        columns: [
           {label: '商品编码', prop: 'goodsNo',  minWidth: 180 },
          { label: '商品名称', prop: 'goodsName', minWidth: 160 },
          { label: '条码', prop: 'barcode', minWidth: 160 },
          { label: '规格', prop: 'goodsSpec', minWidth: 100 },
          { label: '基本单位', prop: 'basUnitName', minWidth: 100 },
          { label: '本月采购金额', prop: 'thisMonthUnitPriceTaxMoney', minWidth: 100 },
          { label: '本月采购数量', prop: 'thisMonthBasUnitQty', minWidth: 100 },
          { label: '本月平均金额', prop: 'thisMonthAvgPrice', minWidth: 100 },
          { label: '上月采购金额', prop: 'lastMonthUnitPriceTaxMoney', minWidth: 100 },
          { label: '上月采购数量', prop: 'lastMonthBasUnitQty', minWidth: 100 },
          { label: '上月平均金额', prop: 'lastMonthAvgPrice', minWidth: 100 },
          { label: '采购差价', prop: 'diffPrice', minWidth: 100 },
          { label: '年初采购金额', prop: 'firstMonthUnitPriceTaxMoney', minWidth: 100 },
          { label: '年初采购数量', prop: 'firstMonthBasUnitQty', minWidth: 100 },
          { label: '年初平均进价', prop: 'firstMonthAvgPrice', minWidth: 100 },
          { label: '最新进价', prop: 'latestPrice', minWidth: 100 }
        ],
        summary: [
          'thisMonthUnitPriceTaxMoney',
          'thisMonthBasUnitQty',
          'thisMonthAvgPrice',
          'lastMonthUnitPriceTaxMoney',
          'lastMonthBasUnitQty',
          'lastMonthAvgPrice',
          'diffPrice',
          'firstMonthUnitPriceTaxMoney',
          'firstMonthBasUnitQty',
          'firstMonthAvgPrice',
          'latestPrice',
        ]
      }
    }
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case 'oepnDetail': //获取点击商品信息
         
          break
        default:
          break
      }
    }
  }
}
</script>
<style lang="scss" scoped>
</style>

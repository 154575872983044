<template>
  <div>
    <div id="chart-container" style="width: 100%; height: 400px"></div>
  </div>
</template>

<script>
export default {
  mounted () {
    // 获取图表容器
    const chartContainer = document.getElementById('chart-container')
    // 使用 echarts 渲染图表
    const myChart = this.$echarts.init(chartContainer)

    const dataset = {
      dimensions: ['name', 'score'],
      source: [
        ['Hannah Krause', 314],
        ['Zhao Qian', 351],
        ['Jasmin Krause ', 287],
        ['Li Lei', 219],
        ['Karle Neumann', 253],
        ['Mia Neumann', 165],
        ['Böhm Fuchs', 318],
        ['Han Meimei', 366]
      ]
    }
    const pieOption = {
      dataset: [dataset],
      series: [
        {
          type: 'pie',
          // 通过 id 关联需要过渡动画的系列
          id: 'Score',
          radius: [0, '50%'],
          universalTransition: true,
          animationDurationUpdate: 1000
        }
      ]
    }
    const barOption = {
      dataset: [dataset],
      xAxis: {
        type: 'category'
      },
      yAxis: {},
      series: [
        {
          type: 'bar',
          // 通过 id 关联需要过渡动画的系列
          id: 'Score',
          // 每个数据都是用不同的颜色
          colorBy: 'data',
          encode: { x: 'name', y: 'score' },
          universalTransition: true,
          animationDurationUpdate: 1000
        }
      ]
    }

    let option = barOption

    setInterval(() => {
      option = option === pieOption ? barOption : pieOption
      // 使用 notMerge 的形式可以移除坐标轴
      myChart.setOption(option, true)
    }, 2000)
  }
}
</script>

<style></style>

<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import { reportDetailListAPI, sumDetailReportForms } from "@/api/purchase/report"; //采购报表
import { listCategory } from "@/api/goods/category";
import { listSupplier } from "@/api/goods/supplier"; //供应商
import { listSimpleQuery } from "@/api/system/dept"; //部门
import { allEmployeeList } from "@/api/system/employee"; //员工
import { allStoreList } from "@/api/system/store"; //仓库

export default {
  name: "room",
  components: { TablePage },
  data() {
    return {
      options: {
        summary: [
          "unitQty",
          "unitTaxMoney",
          "unitPriceTaxMoney",
        ],
        mutiSelect: false, // 多选
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 10,
        },
        getListApi: reportDetailListAPI,
        getSummaryApi: sumDetailReportForms,
        body: {},
        listNo: true, // 序号
        title: "采购明细表",
        check: [], // 选中数据
        rowKey: "1703967014381789184",
        exportOption: {
          show: true,
          exportApi: 'exportDetailReportForms',
          exportName: '采购明细表',
          timeout: 60*5*1000
        },
        search: [
          {
            label: "单据日期",
            type: "datetimerange",
            model: "",
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "day",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "input",
            tip:
              "单据编号、商品编号、商品名称、仓库编号、仓库名称、供应商编号、供应商名称",
            model: "",
            filter: "query",
          },
          {
            label: "单据类型",
            seniorSearch: true,
            type: "local",
            model: "",
            filter: "billTypes",
            option: {
              multiple: true,
              data: [
                {
                  label: "采购入库",
                  value: "110102",
                },
                {
                  label: "采购退货",
                  value: "110103",
                },
              ],
              label: "label",
              value: "value",
            },
          },
          this.$select({
            key: "listGoods",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listCategory",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listSupplier",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listStore",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          {
            label: "部门",
            seniorSearch: true,
            type: "local",
            model: "",
            filter: "deptIds",
            option: {
              remote: listSimpleQuery,
              dataKey: "data",
              label: "deptName",
              value: "deptId",
              filterable: true,
              multiple: true,
            },
          },
          {
            label: "采购员",
            seniorSearch: true,
            type: "local",
            model: "",
            filter: "purEmployeeIds",
            option: {
              multiple: true,
              remote: allEmployeeList,
              dataKey: "data",
              label: "employeeName",
              value: "employeeId",
              filterable: true,
            },
          },
          // {
          //   label: '创建人',
          //   seniorSearch: true,
          //   type: 'local',
          //   model: '',
          //   filter: 'createEmployeeId',
          //   option: {
          //     remote: allEmployeeList,
          //     dataKey: 'rows',
          //     label: 'employeeName',
          //     value: 'employeeId',
          //     filterable: true
          //   }
          // },
          // {
          //   label: '审核人',
          //   seniorSearch: true,
          //   type: 'local',
          //   model: '',
          //   filter: 'auditEmployeeId',
          //   option: {
          //     remote: allEmployeeList,
          //     dataKey: 'rows',
          //     label: 'employeeName',
          //     value: 'employeeId',
          //     filterable: true
          //   }
          // },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        columns: [
          {
            prop: "billNo",
            label: "单据编号",
            type: "link",
            click: "routerLink",
            minWidth: 170,
          },
          {
            prop: "billDate",
            label: "单据日期",
            minWidth: 100,
          },
          {
            prop: "billTypeName",
            label: "单据类型",
            minWidth: 110,
          },
          // {
          //   prop: "goodsNo",
          //   label: "商品编码",
          //   minWidth: 130,
          // },
          {
            prop: "goodsName",
            label: "商品名称",
            minWidth: 130,
          },
          // {
          //   prop: "barcode",
          //   label: "条码",
          //   minWidth: 130,
          // },
          {
            prop: "categoryName",
            label: "商品类别",
            minWidth: 120,
          },
          {
            prop: "partnerName",
            label: "供应商",
            minWidth: 160,
          },
          {
            prop: "storeName",
            label: "仓库",
            minWidth: 130,
          },
          {
            prop: "goodsSpec",
            label: "规格",
            minWidth: 80,
          },
          // {
          //   prop: "unitName",
          //   label: "基本单位",
          //   minWidth: 80,
          // },
          // {
          //   prop: "basUnitQty",
          //   label: "基本单位数量",
          //   minWidth: 100,
          // },
          {
            prop: "unit",
            label: "单位",
            minWidth: 80,
          },
          {
            prop: "unitQty",
            label: "数量",
            minWidth: 80,
          },
          {
            prop: "unitPrice",
            label: "单价",
            minWidth: 100,
          },
          // {
          //   prop: "unitTaxPrice",
          //   label: "含税单价",
          //   minWidth: 100,
          // },
          // {
          //   prop: "taxRate",
          //   label: "税率",
          //   minWidth: 80,
          // },
          // {
          //   prop: "unitTaxMoney",
          //   label: "税额",
          //   minWidth: 100,
          // },
          {
            prop: "unitPriceTaxMoney",
            label: "价税合计",
            minWidth: 120,
          },
          // {
          //   prop: "deptName",
          //   label: "部门",
          //   minWidth: 120,
          // },
          {
            prop: "purEmployeeName",
            label: "采购员",
            minWidth: 120,
          },
          {
            prop: "billRemark",
            label: "单据备注",
            minWidth: 120,
          },
          {
            prop: "remark",
            label: "备注",
            minWidth: 120,
          },
          // {
          //   prop: "sourceBillTypeName",
          //   label: "源单类型",
          //   minWidth: 80,
          // },
          // {
          //   prop: "sourceBillNo",
          //   label: "源单单号",
          //   minWidth: 120,
          // },
        ],
        list: [],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "routerLink":
          {
            const objName = {
              110102: "PurchaseInstoreDetail",
              110103: "PurchaseBackDetail",
            };
            this.$router.push({
              name: objName[row.billType],
              query: {
                billId: row.billId,
                type: "Update",
              },
            });
          }
          break;
        default:
          break;
      }
    },
    seniorSearch() {},
  },
};
</script>
<style lang="scss" scoped></style>

<template>
  <!-- 采购入库单 -->
  <div class="Instore-detail">
    <detail :billType="billType">
      <!-- 插槽 -->
      <template #tableBillSource>
        <el-table-column
          label="单据源"
          width="160"
          align="center"
          :formatter="id2Content"
          show-overflow-tooltip
        >
        </el-table-column>
      </template>
      <template #tableSpecial>
        <el-table-column label="应收数量" prop="importUnitQty" align="center"> </el-table-column>
      </template>
    </detail>
  </div>
</template>

<script>
import detail from '@/views/components/bill/detail-layout.vue'
export default {
  name: 'PurchaseInstoreDetail',
  components: { detail },
  data () {
    return {
      billType: '110102'
    }
  },
  methods: {
    id2Content (row, column, cellValue, index) {
      if(row.sourceBillDetailId) {
        return '采购订单'
      }
    }
  }
}
</script>

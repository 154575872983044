<template>
  <div class="wrap">
    <el-card>
      <div id="chart-container" style="width: 100%; height: 400px" />
      <el-slider
        class="slider"
        v-model="selectedDays"
        range
        :min="minTime"
        :max="maxTime"
        :format-tooltip="formatTooltip"
        @change="sliderChange"
      />
    </el-card>
    <el-card class="marT10 padd15">
      <div class="x-bc">
        <div class="distributionTitleText">
          <span>采购分布</span>
          <span class="marR5 marL5">（{{ "按" + distributionTopName }}）</span>
          <span> TOP 20</span>
        </div>
        <div>
          <el-radio-group
            v-model="distributionParams.filterTime"
            size="mini"
            @change="distributionChange"
          >
            <el-radio-button label="thisWeek">本周</el-radio-button>
            <el-radio-button label="thisMonth">本月</el-radio-button>
            <el-radio-button label="thisYear">本年</el-radio-button>
          </el-radio-group>
          <el-select
            class="distributionSelect marL10 marR20"
            v-model="distributionParams.searchType"
            placeholder="全部"
            filterable
            @change="distributionChange"
          >
            <el-option
              v-for="item in searchTypeList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div ref="chartDistribution" style="width: 100%; height: 400px" />
    </el-card>
    <el-card class="marT10 padd15">
      <div class="x-bc">
        <div class="distributionTitleText">
          <span>采购排行</span>
          <span class="marR5 marL5">（{{ "按" + rankeName }}）</span>
        </div>
        <div>
          <el-radio-group
            v-model="rankeNameParams.filterTime"
            size="mini"
            @change="rankeNameChange"
          >
            <el-radio-button label="thisWeek">本周</el-radio-button>
            <el-radio-button label="thisMonth">本月</el-radio-button>
            <el-radio-button label="thisYear">本年</el-radio-button>
          </el-radio-group>
          <el-select
            class="distributionSelect marL10 marR20"
            v-model="rankeNameParams.searchType"
            placeholder="全部"
            filterable
            @change="rankeNameChange"
          >
            <el-option
              v-for="item in searchTypeList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <el-table
        class="marT10"
        v-loading="loadingTable"
        ref="multipleTable"
        tooltip-effect="dark"
        :data="rankeTableData"
        border
        max-height="500"
        height="500"
        :default-sort="{ prop: 'billQty', order: 'descending' }"
      >
        <el-table-column
          label="序号"
          align="center"
          prop="userId"
          width="80"
          type="index"
        />
        <el-table-column :label="rankeName" align="center" prop="typeName" width="600" />
        <el-table-column label="采购数量" sortable align="center" prop="billQty" />
        <el-table-column label="采购金额" sortable align="center" prop="billMoney" />
      </el-table>
    </el-card>
  </div>
</template>

<script>
import {
  dataAnalyseListAPI,
  distributionListAPI,
  rankeNameListAPI,
} from "@/api/purchase/report"; //采购报表
export default {
  name: "dataAnalyse",
  data() {
    const today = new Date();
    const lastYearToday = new Date(
      today.getFullYear() - 1,
      today.getMonth(),
      today.getDate()
    );
    return {
      //采购排行列表
      rankeTableData: [],
      //遮罩
      loadingTable: false,
      rankeName: "商品",
      //采购排行
      rankeNameParams: {
        filterTime: "thisWeek",
        searchType: "goods",
      },
      distributionTopName: "商品",
      //采购分布
      distributionParams: {
        filterTime: "thisWeek",
        searchType: "goods",
      },
      //分布筛选
      searchTypeList: [
        { name: "商品", value: "goods" },
        { name: "商品类别", value: "goodsCategory" },
        { name: "供应商", value: "partner" },
        { name: "采购员", value: "employee" },
        { name: "仓库", value: "store" },
        { name: "部门", value: "dept" },
      ],
      //前年
      minTime: lastYearToday.getTime(),
      //今年
      maxTime: today.getTime(),
      //滑动值
      selectedDays: [lastYearToday, today],
      //采购金额
      purchases: [
        {
          name: "采购订单",
          type: "line",
          stack: "Total",
          data: [120, 132, 101, 134, 90, 230, 210],
        },
        {
          name: "采购入库单",
          type: "line",
          stack: "Total",
          data: [220, 182, 191, 234, 290, 330, 310],
        },
        {
          name: "采购退货单",
          type: "line",
          stack: "Total",
          data: [150, 232, 201, 154, 190, 330, 410],
        },
      ],
      //采购金额日期
      purchasesDate: [
        "2022-06-03",
        "2022-06-04",
        "2022-06-05",
        "2022-06-06",
        "2022-06-07",
        "2022-06-08",
        "2022-06-09",
      ],
      //采购分布
      chartData: [],
    };
  },
  mounted() {
    //滑块渲染
    this.sliderChange();
    //横向采购分布
    this.distributionChange();
    //采购排行
    this.rankeNameChange();
  },
  watch: {
    //侦听采购分布
    "distributionParams.searchType": {
      handler(newVal) {
        if (newVal) {
          const objType = {
            goods: "商品",
            goodsCategory: "商品类别",
            partner: "供应商",
            employee: "采购员",
            store: "仓库",
            dept: "部门",
          };
          this.distributionTopName = objType[newVal];
        }
      },
      immediate: true,
    },
    //侦听采购排行
    "rankeNameParams.searchType": {
      handler(newVal) {
        if (newVal) {
          const objType = {
            goods: "商品",
            goodsCategory: "商品类别",
            partner: "供应商",
            employee: "采购员",
            store: "仓库",
            dept: "部门",
          };
          this.rankeName = objType[newVal];
        }
      },
      immediate: true,
    },
  },
  methods: {
    //排行请求改变
    async rankeNameChange() {
      //加载遮罩
      this.loadingTable = true;
      const res = await rankeNameListAPI(this.rankeNameParams);
      if (res) {
        this.rankeTableData = res;
      } else {
        this.rankeTableData = [];
      }
      //关闭遮罩
      this.loadingTable = false;
    },
    //分布请求改变
    async distributionChange() {
      const res = await distributionListAPI(this.distributionParams);
      this.chartData = res;
      this.barGraph();
    },
    //右边横向条形图
    barGraph() {
      // 使用 echarts 渲染图表  采购趋势
      const myChartDistribution = this.$echarts.init(this.$refs.chartDistribution);
      const sortData = [...this.chartData].sort((a, b) => a.billMoney - b.billMoney); // 复制并排序数据
      // 绘制图表
      myChartDistribution.setOption({
        // title: {
        //   text: '横向排序柱状图'
        // },
        // 全局调色盘。
        color: "#409eff",
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          top: 15,
          containLabel: true,
        },
        xAxis: {
          type: "value",
          axisLabel: {
            color: "#333333", // 设置 x 轴刻度值的文本颜色为微软雅黑
          },
          axisLine: {
            lineStyle: {
              // x 轴轴线样式
              type: "dashed", // 虚线
              width: 1, // 轴线宽度
              color: "#ccc", // 轴线颜色
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed", // 提示设置虚线
            },
          },
        },
        yAxis: {
          type: "category",
          data: sortData.map((item) => item.typeName),
          axisLine: {
            lineStyle: {
              color: "#333333", // 修改 y 轴轴线颜色为绿色
            },
          },
          axisTick: {
            show: false,
          },
        },
        tooltip: {
          // 添加 tooltip 组件
          trigger: "axis",
          //阴影
          axisPointer: {
            // 设置 axisPointer
            // type: 'shadow',
            // axis: 'auto',
            // animation: 'auto',
            // animationDurationUpdate: 200,
            // animationEasingUpdate: 'exponentialOut',
            // crossStyle: {
            //   color: '#999',
            //   width: 1,
            //   type: 'dashed'
            // }
          },
          z: 60,
          show: true,
          showContent: true,
          triggerOn: "mousemove|click",
          alwaysShowContent: false,
          displayMode: "single",
          renderMode: "auto",
          confine: null,
          showDelay: 0,
          hideDelay: 100,
          transitionDuration: 0.4,
          enterable: false,
          backgroundColor: "rgba(0, 0, 0, .8)",
          shadowBlur: 10,
          shadowColor: "rgba(0, 0, 0, .2)",
          shadowOffsetX: 1,
          shadowOffsetY: 2,
          borderRadius: 4,
          borderWidth: 1,
          padding: null,
          extraCssText: "",
          textStyle: {
            color: "#fff",
            fontSize: 14,
          },
        },
        series: {
          type: "bar",
          data: sortData.map((item) => item.billMoney),
          label: {
            show: true,
            position: "right",
            color: "#333333",
          },
          barWidth: 20,
        },
      });
      //图表大小变动从新渲染，动态自适应
      window.addEventListener("resize", function () {
        myChartDistribution.resize();
      });
    },
    //滑块改变
    async sliderChange() {
      let arr = this.selectedDays.map((dateItem) => {
        const dateString = this.getTimeConversion(dateItem);
        return dateString;
      });
      const res = await dataAnalyseListAPI({
        beginBillDate: arr[0],
        endBillDate: arr[1],
      });
      //采购订单
      this.$set(this.purchases[0], "data", res.purchaseQtys);
      //采购入库单
      this.$set(this.purchases[1], "data", res.saleQtys);
      //采购退货单
      this.$set(this.purchases[2], "data", res.refundQtys);
      //时间段
      this.purchasesDate = res.everyDays;
      //渲染趋势
      this.getRenderTrend();
    },
    //滑块提示
    formatTooltip(val) {
      const tooltipValue = this.getTimeConversion(val);
      return tooltipValue;
    },
    //渲染趋势
    getRenderTrend() {
      // 获取图表容器
      let chartContainer = document.getElementById("chart-container");
      // 使用 echarts 渲染图表  采购趋势
      const myChart = this.$echarts.init(chartContainer);

      myChart.setOption({
        title: {
          text: "采购趋势",
          z: 6,
          show: true,
          target: "blank",
          subtext: "",
          subtarget: "blank",
          left: 20,
          top: 10,
          backgroundColor: "rgba(0,0,0,0)",
          borderColor: "#ccc",
          borderWidth: 0,
          padding: 5,
          itemGap: 10,
          //文字主标题样式的配置项。该配置项用于设置副标题的字体大小、颜色、字体样式等属性
          textStyle: {
            fontSize: 16,
            fontWeight: "bold",
            color: "#464646",
          },
          //文字副标题样式的配置项。该配置项用于设置副标题的字体大小、颜色、字体样式等属性
          subtextStyle: {
            fontSize: 12,
            color: "#6E7079",
          },
        },
        //该属性用于设置Echarts图表的暗色模式。它有三个选项：'light'（浅色主题），'dark'（深色主题）和'auto'（根据系统主题自动切换）。当该选项设置为'auto'时，Echarts会根据当前系统是否开启暗色模式来自动切换浅色或深色主题。
        darkMode: "auto",
        //该属性用于设置Echarts图表的颜色映射方式。它有两个选项：'series'和'value'。当该选项设置为'series'时，Echarts会根据系列的顺序自动分配颜色；当该选项设置为'value'时，Echarts会根据数据的大小自动分配颜色，颜色越深代表数据越大。
        colorBy: "series",
        // 全局调色盘。
        color: [
          "#5470c6",
          "#91cc75",
          "#ea7ccc",
          "#9a60b4",
          "#fac858",
          "#ee6666",
          "#73c0de",
          "#3ba272",
          "#fc8452",
        ],
        // ECharts 中用于设置渐变颜色的属性
        gradientColor: ["#f6efa6", "#d88273", "#bf444c"],
        //控制文本的样式和颜色
        textStyle: {
          fontFamily: "Microsoft YaHei",
          fontSize: 12,
          fontStyle: "normal",
          fontWeight: "normal",
        },
        //用于控制图表中元素状态的动画效果。当 stateAnimation 属性被设置为 true 时，图表将会在以下状态变化时，出现动画过渡效果
        stateAnimation: {
          duration: 300,
          easing: "cubicOut",
        },
        //动画的开启模式，可选值为  'auto' ， true  和  false ，默认为  'auto' 。当设置为  'auto'  时，将会在动画数据大于  setupAnimationThreshold  时开启初始动画，反之则不开启；当设置为  true  时，将会一直开启动画；当设置为  false  时，不会开启动画。
        animation: "auto",
        //初始动画的时长，单位为毫秒，默认为 1000 毫秒。
        animationDuration: 1000,
        //数据更新动画的时长，单位为毫秒，默认为 500 毫秒。
        animationDurationUpdate: 500,
        // 初始动画的缓动效果
        animationEasing: "cubicInOut",
        // 数据更新动画的缓动效果
        animationEasingUpdate: "cubicInOut",
        //动画元素的阈值，当动画元素个数大于该值时，才会开启初始动画，反之则不开启。默认值为 2000。
        animationThreshold: 2000,
        //渐进式渲染的阈值，当数据量超过该阈值时，图表将采取渐进式渲染，每帧绘制一部分数据，从而避免一次性绘制大量数据带来的页面卡顿问题。默认值为 3000。
        progressiveThreshold: 3000,
        // 渐进式渲染每一帧渲染的数据量，单位为数据项个数。默认值为 400。
        progressive: 400,
        // 高亮图形的阈值（即鼠标悬浮在图形上时，显示的提示框和高亮样式）。当图形数量超过该阈值时，鼠标进入图形区域时不会触发高亮动画，反之则会。默认值为 3000。
        hoverLayerThreshold: 3000,
        // 是否使用协调世界时（UTC）。默认值为 false。
        useUTC: false,
        //图表中为标记线添加指示器，以展示当前数据的具体数值。 axisPointer 通常用于与 tooltip （提示框）组件配合使用，可以在鼠标滑动到标记线处时，自动弹出提示框，展示当前数据的详细信息。  坐标轴指示器
        axisPointer: [
          {
            show: "auto",
            z: 50,
            type: "line",
            snap: false,
            triggerTooltip: true,
            value: null,
            status: null,
            animation: null,
            animationDurationUpdate: 200,
            lineStyle: {
              color: "#B9BEC9",
              width: 1,
              type: "dashed",
            },
            shadowStyle: {
              color: "rgba(210,219,238,0.2)",
            },
            label: {
              show: true,
              formatter: null,
              precision: "auto",
              margin: 3,
              color: "#fff",
              padding: [5, 7, 5, 7],
              backgroundColor: "auto",
              borderColor: null,
              borderWidth: 0,
              borderRadius: 3,
            },
            handle: {
              show: false,
              icon:
                "M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4h1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7v-1.2h6.6z M13.3,22H6.7v-1.2h6.6z M13.3,19.6H6.7v-1.2h6.6z",
              size: 45,
              margin: 50,
              color: "#333",
              shadowBlur: 3,
              shadowColor: "#aaa",
              shadowOffsetX: 0,
              shadowOffsetY: 2,
              throttle: 40,
            },
          },
        ],
        //鼠标悬停或点击数据点时显示相关的数据信息
        tooltip: [
          {
            //触发提示框的方式，可以是  'item' （数据项触发）或者  'axis' （坐标轴触发）
            trigger: "axis",
            z: 60,
            show: true,
            showContent: true,
            triggerOn: "mousemove|click",
            alwaysShowContent: false,
            displayMode: "single",
            renderMode: "auto",
            confine: null,
            showDelay: 0,
            hideDelay: 100,
            transitionDuration: 0.4,
            enterable: false,
            backgroundColor: "#fff",
            shadowBlur: 10,
            shadowColor: "rgba(0, 0, 0, .2)",
            shadowOffsetX: 1,
            shadowOffsetY: 2,
            borderRadius: 4,
            borderWidth: 1,
            padding: null,
            extraCssText: "",
            //提示框的指示器样式和行为
            axisPointer: {
              type: "line",
              axis: "auto",
              animation: "auto",
              animationDurationUpdate: 200,
              animationEasingUpdate: "exponentialOut",
              crossStyle: {
                color: "#999",
                width: 1,
                type: "dashed",
              },
            },
            //提示样式
            textStyle: {
              color: "#666",
              fontSize: 14,
            },
          },
        ],
        //yAxis （y轴）是一个配置项，用于定义图表的y轴
        yAxis: [
          {
            //-  type ：y轴的类型，可以是  'value'  值轴， 'category'  类目轴， 'time'  时间轴， 'log'  对数轴等。
            type: "value",
            boundaryGap: [0, 0],
            axisLine: {
              show: false,
              onZero: true,
              onZeroAxisIndex: null,
              lineStyle: {
                color: "#6E7079",
                width: 1,
                type: "dashed",
              },
              symbol: ["none", "none"],
              symbolSize: [10, 15],
            },
            axisTick: {
              show: "auto",
              inside: false,
              length: 5,
              lineStyle: {
                with: 1,
              },
            },
            splitNumber: 5,
            minorTick: {
              show: false,
              splitNumber: 5,
              length: 3,
            },
            minorSplitLine: {
              show: false,
              lineStyle: {
                color: "#F4F7FD",
                width: 1,
              },
            },
            show: true,
            z: 0,
            inverse: false,
            name: "",
            nameLocation: "end",
            nameRotate: null,
            nameTruncate: {
              maxWidth: null,
              ellipsis: "...",
              placeholder: ".",
            },
            nameGap: 15,
            silent: false,
            triggerEvent: false,
            tooltip: {
              show: false,
            },
            axisLabel: {
              show: true,
              inside: false,
              rotate: 0,
              showMinLabel: null,
              showMaxLabel: null,
              margin: 8,
              fontSize: 12,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: ["#E0E6F1"],
                width: 1,
                type: "solid",
              },
            },
            splitArea: {
              show: false,
              areaStyle: {
                color: ["rgba(250,250,250,0.2)", "rgba(210,219,238,0.2)"],
              },
            },
            offset: 0,
          },
        ],
        //xAxis （x轴）是一个配置项，用于定义图表的x轴
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: this.purchasesDate,
            deduplication: null,
            splitLine: {
              show: false,
              lineStyle: {
                color: ["#E0E6F1"],
                width: 1,
                type: "solid",
              },
            },
            axisTick: {
              alignWithLabel: false,
              interval: "auto",
              show: true,
              inside: false,
              length: 5,
              lineStyle: {
                with: 1,
              },
            },
            axisLabel: {
              interval: "auto",
              show: true,
              inside: false,
              rotate: 0,
              showMinLabel: null,
              showMaxLabel: null,
              margin: 8,
              fontSize: 12,
            },
            show: true,
            z: 0,
            inverse: false,
            name: "",
            nameLocation: "end",
            nameRotate: null,
            nameTruncate: {
              maxWidth: null,
              ellipsis: "...",
              placeholder: ".",
            },
            nameGap: 15,
            silent: false,
            triggerEvent: false,
            tooltip: {
              show: false,
            },
            axisPointer: {
              status: "hide",
              value: 6,
            },
            axisLine: {
              show: true,
              onZero: true,
              onZeroAxisIndex: null,
              lineStyle: {
                color: "#6E7079",
                width: 1,
                type: "solid",
              },
              symbol: ["none", "none"],
              symbolSize: [10, 15],
            },
            splitArea: {
              show: false,
              areaStyle: {
                color: ["rgba(250,250,250,0.2)", "rgba(210,219,238,0.2)"],
              },
            },
            offset: 0,
          },
        ],
        legend: {
          data: ["采购订单", "采购入库单", "采购退货单"],
          // left: 'right',
          right: 50,
          top: 10,
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
          show: false,
          z: 0,
          top: 60,
          backgroundColor: "rgba(0,0,0,0)",
          borderWidth: 1,
          borderColor: "#ccc",
        },
        series: this.purchases,
      });
      // 设置resize选项
      window.addEventListener("resize", function () {
        myChart.resize(); // 重新计算和绘制图表
      });
    },
    //滑块时间段转换
    getTimeConversion(value) {
      const date = new Date(value); // 创建一个 Date 对象，参数为时间戳
      const year = date.getFullYear(); // 年份
      const month = date.getMonth() + 1; // 月份，getMonth 方法的返回值范围是 0-11，需要加 1
      const day = date.getDate(); // 日期
      // 将年、月、日拼接成字符串，格式为 yyyy-mm-dd
      const dateString = `${year}-${month
        .toString()
        .padStart(2, "0")}-${day.toString().padStart(2, "0")}`;
      return dateString;
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  background-color: #eaeaea;
  padding: 6px 10px;
  ::v-deep canvas {
    width: 100% !important;
  }
  //时间拖动
  .slider {
    padding: 0 3%;
  }
  //分布标题
  .distributionTitleText {
    margin-left: 16px;
    font-weight: 700;
  }
  //分布选择
  .distributionSelect {
    width: 130px;
    ::v-deep .el-input--medium .el-input__inner {
      height: 28px;
      line-height: 28px;
    }
  }
}
//卡片默认边距0
::v-deep .el-card__body {
  padding: 0;
  margin: 0;
}
//下拉框
::v-deep .el-select .el-input .el-select__caret {
  margin-top: 4px;
}
::v-deep .el-slider__button-wrapper {
  z-index: 0;
}
</style>

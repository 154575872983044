<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" />
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import { summaryListAPI, sumDetailGoodsSummaryForms } from "@/api/purchase/report"; //采购报表
export default {
  name: "payQuery",
  components: { TablePage },
  data() {
    return {
      options: {
        summary: "",
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        body: {},
        title: "采购汇总表",
        rowKey: "billId",
        labelWidth: "70",
        search: [
          {
            label: "单据日期",
            type: "datetimerange",
            model: "",
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "day",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            label: "单据类型",
            seniorSearch: true,
            type: "local",
            model: "",
            filter: "billTypes",
            option: {
              data: [
                { label: "采购入库", value: "110102" },
                { label: "采购退货", value: "110103" },
              ],
              label: "label",
              value: "value",
              filterable: true,
              multiple: true,
            },
          },
          this.$select({
            key: "listCategory",
            option: {
              seniorSearch: true,
              hide: () => ["goods", "goodsCategory"].includes(this.options.tabColumnType),
              option: { multiple: true },
            },
          }),
          this.$select({
            key: "listGoods",
            option: {
              seniorSearch: true,
              hide: () => ["goods"].includes(this.options.tabColumnType),
              option: { multiple: true },
            },
          }),
          this.$select({
            key: "listSupplier",
            option: {
              seniorSearch: true,
              hide: () => ["partner"].includes(this.options.tabColumnType),
              option: { multiple: true },
            },
          }),
          this.$select({
            key: "listStore",
            option: {
              seniorSearch: true,
              hide: () => ["store"].includes(this.options.tabColumnType),
              option: { multiple: true },
            },
          }),
          this.$select({
            key: "listPurEmployee",
            option: {
              seniorSearch: true,
              option: { multiple: true },
            },
          }),
          this.$select({
            key: "listPurCreateEmployee",
            option: {
              seniorSearch: true,
              option: { multiple: true },
            },
          }),
          this.$select({
            key: "listPurAuditEmployee",
            option: {
              seniorSearch: true,
              option: { multiple: true },
            },
          }),
          this.$select({
            key: "listStore",
            option: {
              seniorSearch: true,
              hide: () => ["goods"].includes(this.options.tabColumnType),
              option: { multiple: true },
            },
          }),
          this.$select({
            key: "listSupplier",
            option: {
              seniorSearch: true,
              hide: () => ["goods"].includes(this.options.tabColumnType),
              option: { multiple: true },
            },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        getListApi: summaryListAPI,
        getSummaryApi: sumDetailGoodsSummaryForms,
        tabsColumns: [
          {
            title: "商品汇总查询",
            exportOption: {
              exportApi: 'exportDetailSummaryForms',
              exportName: '商品汇总查询'
            },
            defaultBody: { searchType: "goods" },
            type: "goods",
            columns: [
              {
                prop: "billTypeName",
                label: "单据类型",
                minWidth: 150,
              },
              {
                prop: "categoryName",
                label: "商品类别",
                minWidth: 150,
              },
              {
                prop: "goodsNo",
                label: "商品编号",
                minWidth: 150,
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
              },
              {
                prop: "barcode",
                label: "商品条码",
                minWidth: 150,
              },
              {
                prop: "unitName",
                label: "基本单位",
                minWidth: 150,
              },
              {
                prop: "basUnitQty",
                label: "基本单位数量",
                minWidth: 150,
              },
              {
                prop: "basUnitPrice",
                label: "基本单位单价",
                minWidth: 150,
              },
              {
                prop: "basUnitTaxPrice",
                label: "基本单位含税单价",
                minWidth: 150,
              },
              {
                prop: "taxRate",
                label: "税率",
                minWidth: 150,
              },
              {
                prop: "unitPriceTaxMoney",
                label: "价税合计",
                minWidth: 150,
              },
              {
                prop: "unitTaxMoney",
                label: "税额",
                minWidth: 150,
              },
            ],
            summary: ['basUnitQty', "unitPriceTaxMoney", "unitTaxMoney", "billNotaxMoney"],
          },
          {
            title: "商品类别汇总查询",
            exportOption: {
              exportApi: 'exportDetailSummaryForms',
              exportName: '商品类别汇总查询'
            },
            defaultBody: { searchType: "goodsCategory" },
            type: "goodsCategory",
            columns: [
              {
                prop: "billTypeName",
                label: "单据类型",
                minWidth: 150,
              },
              {
                prop: "categoryNo",
                label: "商品类别编号",
                minWidth: 150,
              },
              {
                prop: "categoryName",
                label: "商品类别名称",
                minWidth: 150,
              },
              {
                prop: "unitPriceTaxMoney",
                label: "价税合计",
                minWidth: 150,
              },
              {
                prop: "unitTaxMoney",
                label: "税额",
                minWidth: 150,
              },
              {
                prop: "billNotaxMoney",
                label: "不含税金额",
                minWidth: 150,
              },
            ],
            summary: ["unitPriceTaxMoney", "unitTaxMoney", "billNotaxMoney"],
          },
          {
            title: "供应商汇总查询",
            exportOption: {
              exportApi: 'exportDetailSummaryForms',
              exportName: '供应商汇总查询'
            },
            defaultBody: { searchType: "partner" },
            type: "partner",
            columns: [
              {
                prop: "billTypeName",
                label: "单据类型",
                minWidth: 150,
              },
              {
                prop: "partnerNo",
                label: "供应商编号",
                minWidth: 150,
              },
              {
                prop: "partnerName",
                label: "供应商名称",
                minWidth: 150,
              },
              {
                prop: "unitPriceTaxMoney",
                label: "价税合计",
                minWidth: 150,
              },
              {
                prop: "unitTaxMoney",
                label: "税额",
                minWidth: 150,
              },
              {
                prop: "billNotaxMoney",
                label: "不含税金额",
                minWidth: 150,
              },
            ],
            summary: ["unitPriceTaxMoney", "unitTaxMoney", "billNotaxMoney"],
          },
          {
            title: "仓库汇总查询",
            exportOption: {
              exportApi: 'exportDetailSummaryForms',
              exportName: '仓库汇总查询'
            },
            defaultBody: { searchType: "store" },
            type: "store",
            columns: [
              {
                prop: "billTypeName",
                label: "单据类型",
                minWidth: 150,
              },
              {
                prop: "storeNo",
                label: "仓库编号",
                minWidth: 150,
              },
              {
                prop: "storeName",
                label: "仓库名称",
                minWidth: 150,
              },
              {
                prop: "unitPriceTaxMoney",
                label: "价税合计",
                minWidth: 150,
              },
              {
                prop: "unitTaxMoney",
                label: "税额",
                minWidth: 150,
              },
              {
                prop: "billNotaxMoney",
                label: "不含税金额",
                minWidth: 150,
              },
            ],
            summary: ["unitPriceTaxMoney", "unitTaxMoney", "billNotaxMoney"],
          },
        ],
      },
    };
  },
};
</script>
